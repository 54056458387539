<script>
import html2pdf from "html2pdf.js";
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import Repository from "@/app/repository/repository-factory";
import { roleService } from "@/app/service/roleService";

const CoreReportRepository = Repository.get("CoreReportRepository");
const UserAccountRepository = Repository.get("UserAccountRepository");

export default {
  page: {
    title: "Raporty - Historia logowania",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      preloader: false,
      raportData: null,
      raportDataByCompany: null,
      raportDataByYearMonth: null,
      form: {
        reportDateRange: null,
        username: [],
        typeOfLogin: [],
      },
      raportFields: {
        Utworzone: "createdAt",
        IP: "info.ip",
        Użytkownik: "info.username",
        Typ: "type.name",
      },
      userSelectOptions: [],
      typeOfLoginSelectOptions: [{
        index: 1,
        id: 1,
        name: "Poprawne logowanie",
        value: "Poprawne logowanie",
        typeOfLogin: "Poprawne logowanie",
        text: "Poprawne logowanie",
      },{
        index: 2,
        id: 2,
        name: "Niepoprawne logowanie",
        value: "Niepoprawne logowanie",
        typeOfLogin: "Niepoprawne logowanie",
        text: "Niepoprawne logowanie",
      }],
      header: {
        title: "Historia logowania",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Raporty",
          },
          {
            text: "Historia logowania",
          },
        ],
      },
    };
  },
  validations: {
    form: {
      reportDateRange: {
        required,
      },
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getAppLogins(params) {
      try {
        const { data } = await CoreReportRepository.getLoginLogs(params);
        this.raportData = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getData(params) {
      this.preloader = true;
      try {
        await Promise.all([this.getAppLogins(params)]);
        this.preloader = false;
      } catch (error) {
        this.preloader = false;
        console.log(error);
      }
    },
    getUsers() {
      UserAccountRepository.getAll()
        .then((response) => {
          let users = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            users.push({
              text: response.data[i].email,
              value: response.data[i].email,
            });
          }

          this.userSelectOptions = users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearAllUsernames() {
      this.form.username = [];
    },
    clearAllTypeOfLogins() {
      this.form.typeOfLogin = [];
    },
    regenerateReport() {
      if (
        this.form.reportDateRange[0] != null &&
        this.form.reportDateRange[1] != null
      ) {
        let params =
          "?startDate=" +
          this.form.reportDateRange[0] +
          "&endDate=" +
          this.form.reportDateRange[1] +
          "&filterType=" +
          this.form.typeOfLogin.map((e) => e.id).join(",") +
          "&filterUsername=" +
          this.form.username.map((e) => e.value).join(",");
        this.getData(params);
      }
    },
    exportToPdf() {
      html2pdf(this.$refs.domElementToPDF, {
        margin: 0.5,
        filename: "reportTableExport.pdf",
        image: { type: "jpeg", quality: 0.98 },
        // html2canvas: { dpi: 192, letterRendering: true },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "A4", orientation: "landscape" },
      });
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="header.title" :items="header.items" />
    <div class="row" v-if="isAdmin">
      <div class="col-12">
        <b-card class="shadow-sm">
          <div class="font-weight-bold" slot="header">Ustawienia parametrów raportu</div>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <label for="reportDateRange">
                Zakres dat
                <em class="text-danger">*</em>
              </label>
              <div class="form-group">
                <date-picker id="reportDateRange" v-model="form.reportDateRange" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" @input="$v.form.reportDateRange.$touch()" :class="{ 'is-invalid': $v.form.reportDateRange.$error, 'is-valid': !$v.form.reportDateRange.$invalid && !$v.form.reportDateRange.$error }" lang="pl" placeholder="YYYY-MM-DD ~ YYYY-MM-DD" range required />
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label class="typo__label">Wybierz użytkownika</label>
                <multiselect v-model="form.username" :multiple="true" :options="userSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz użytkownika" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych użytkowników: {{ values.length }}</span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="form.username.length" @mousedown.prevent.stop="clearAllUsernames(props.search)"></div>
                  </template>
                  <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                </multiselect>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label class="typo__label">Typ logowania</label>
                <multiselect v-model="form.typeOfLogin" :multiple="true" :options="typeOfLoginSelectOptions" :preserve-search="true" track-by="name" label="text" placeholder="Wybierz rodzaj logowania" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych typów: {{ values.length }}</span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="form.typeOfLogin.length" @mousedown.prevent.stop="clearAllTypeOfLogins(props.search)"></div>
                  </template>
                  <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                </multiselect>
              </div>
            </div>
            <div class="col-md-6 text-left mb-3">
              <download-excel class="btn btn-success" :stringifyLongNum="true" v-if="raportData && isAdmin" :data="raportData" :fields="raportFields" worksheet="My Worksheet" name="filename.xls">
                <i class="mdi mdi-file-excel mr-2"></i>Pobierz Excel
              </download-excel>
              <button class="btn btn-info ml-2" v-if="raportData && isAdmin" @click="exportToPdf">Zrzut tabeli do PDF</button>
            </div>
            <div class="col-md-6 text-right mb-3">
              <button v-if="isAdmin" class="btn btn-dark" @click="regenerateReport" :disabled="$v.form.$invalid" type="submit"><i class="mdi mdi-cogs mr-2"></i>Generuj raport</button>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-12" v-if="raportData">
        <div class="card shadow-sm">
          <div class="table-responsive mb-0">
            <table class="table table-sm border-bottom" ref="domElementToPDF">
              <thead>
                <tr class="bg-soft-info" v-if="raportData.length > 0">
                  <th colspan="5" class="text-dark pl-2">Raport historii logowania użytkowników systemu</th>
                </tr>
                <tr class="bg-dark text-white">
                  <th class="text-center">#</th>
                  <th>Utworzone</th>
                  <th>Adres IP</th>
                  <th>Użytkownik</th>
                  <th>Typ</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in raportData">
                  <tr :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>{{ item.createdAt }}</td>
                    <td>{{ item.info.ip }}</td>
                    <td>{{ item.info.username }}</td>
                    <td>{{ item.type.name }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="card-body">
              <template v-if="!raportData || raportData.length == 0">
                <div class="d-flex justify-content-center">
                  <b>Nie znaleziono elementów</b>
                </div>
              </template>
              <template v-else>
                <div class="d-flex justify-content-between">
                  Znaleziono {{ raportData.length | rekord }} w zakresie dat {{ form.reportDateRange[0] + ' ~ ' + form.reportDateRange[1]}}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
